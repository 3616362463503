import * as React from "react"
import { Helmet } from "react-helmet"
import Header from '../components/Header'
import Footer from '../components/Footer'
// @ts-ignore
import cars from '../img/cars.png'
// @ts-ignore
import carIcon from '../img/bil60x60.png'
// @ts-ignore
import truckIcon from '../img/truck60x60.png'
// @ts-ignore
import busIcon from '../img/bus60x60.png'
// @ts-ignore
import motorbikeIcon from '../img/bike60x60.png'
// @ts-ignore
import boatIcon from '../img/boat60x60.png'
// @ts-ignore
import machineIcon from '../img/machine60x60.png'
import { Link } from "gatsby"

export default () => {
  return (
    <main className="servicespage">
      <Helmet>
        <title>Services - MOP Floorcheck</title>
        <link rel="canonical" href="https://mopfloorcheck.com/services" />
      </Helmet>
      <Header tab="services" />
      <section className="intro">
        <div className="intro-content">
          <h2>OUR SERVICES</h2>
          <hr />
          <p>We adapt to the tasks you provide us</p>
        </div>
      </section>
      <section className="infobox-section">
        <div className="container">
          <div className="infobox">
            <h2>{new Date().getFullYear() - 1998} years of experience</h2>
            <div className="row">
              <div className="col-md-9">
                <p>MOP Floorcheck provides audits to the financial sector and importers in all Nordic countries.</p>
                <p>Our services are stock audits. The only requirement is that the asset must be identifiable by a chassis number or similar.</p>
                <p>We tailor our services to our customers’ needs, ensuring that workflow and reporting integrates perfectly into your existing models.</p>
                <p>All services are managed by our main office in Copenhagen, which currently handles more than 1,200 audits per month.</p>
              </div>
              <div className="col-md-3">
                <img src={cars} />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="today-we-audit">
        <div className="container">
          <h2>Today we audit</h2>
          <hr />
          <div className="row">
            <div className="col">
              <img src={carIcon} /> Cars
            </div>
            <div className="col">
              <img src={truckIcon} /> Trucks
            </div>
            <div className="col">
              <img src={busIcon} /> Caravans
            </div>
          </div>
          <div className="row">
            <div className="col">
              <img src={motorbikeIcon} /> Motorcycles
            </div>
            <div className="col">
              <img src={boatIcon} /> Boats
            </div>
            <div className="col">
              <img src={machineIcon} /> Industrial machinery
            </div>
          </div>
        </div>
      </section>
      <section className="new-challenge">
        <div className="container">
          <h2>DO YOU HAVE A NEW CHALLENGE FOR US?</h2>
          <hr />
          <p>We work with market leading firms in the Nordics, providing our customers with audits of the highest quality.</p>
          <Link to="/contact" className="fusion-btn">MAKE INQUIRY</Link>
        </div>
      </section>
      <Footer />
    </main>
  )
}
